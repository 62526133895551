const TEAMS = {
  nfl_teams: [
    {
      name: "Buffalo Bills",
      id: "NFL001_BBILLS",
      icon: "Teams/nfl/NFL001_BBILLS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Miami Dolphins",
      id: "NFL002_MDOLPHINS",
      icon: "Teams/nfl/NFL002_MDOLPHINS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "New England Patriots",
      id: "NFL003_NEPATRIOTS",
      icon: "Teams/nfl/NFL003_NEPATRIOTS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "New York Jets",
      id: "NFL004_NYJETS",
      icon: "Teams/nfl/NFL004_NYJETS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Baltimore Ravens",
      id: "NFL005_BRAVENS",
      icon: "Teams/nfl/NFL005_BRAVENS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Cincinnati Bengals",
      id: "NFL006_CBENGALS",
      icon: "Teams/nfl/NFL006_CBENGALS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Cleveland Browns",
      id: "NFL007_CBROWNS",
      icon: "Teams/nfl/NFL007_CBROWNS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Pittsburgh Steelers",
      id: "NFL008_PSTEELERS",
      icon: "Teams/nfl/NFL008_PSTEELERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Houston Texans",
      id: "NFL009_HTEXANS",
      icon: "Teams/nfl/NFL009_HTEXANS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Indianapolis Colts",
      id: "NFL010_ICOLTS",
      icon: "Teams/nfl/NFL010_ICOLTS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Jacksonville Jaguars",
      id: "NFL011_JJAGUARS",
      icon: "Teams/nfl/NFL011_JJAGUARS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Tennessee Titans",
      id: "NFL012_TTITANS",
      icon: "Teams/nfl/NFL012_TTITANS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Denver Broncos",
      id: "NFL013_BBRONCOS",
      icon: "Teams/nfl/NFL013_BBRONCOS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Kansas City Chiefs",
      id: "NFL014_KCCHIEFS",
      icon: "Teams/nfl/NFL014_KCCHIEFS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Las Vegas Raiders",
      id: "NFL015_LVRAIDERS",
      icon: "Teams/nfl/NFL015_LVRAIDERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Los Angeles Chargers",
      id: "NFL016_LACHARGERS",
      icon: "Teams/nfl/NFL016_LACHARGERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Dallas Cowboys",
      id: "NFL017_DCOWBOYS",
      icon: "Teams/nfl/NFL017_DCOWBOYS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "New York Giants",
      id: "NFL018_NYGIANTS",
      icon: "Teams/nfl/NFL018_NYGIANTS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Philadelphia Eagles",
      id: "NFL019_PEAGLES",
      icon: "Teams/nfl/NFL019_PEAGLES.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Washington Commanders",
      id: "NFL020_WCOMMANDERS",
      icon: "Teams/nfl/NFL020_WCOMMANDERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Chicago Bears",
      id: "NFL021_CBEARS",
      icon: "Teams/nfl/NFL021_CBEARS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Detroit Lions",
      id: "NFL022_DLIONS",
      icon: "Teams/nfl/NFL022_DLIONS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Green Bay Packers",
      id: "NFL023_GBPACKERS",
      icon: "Teams/nfl/NFL023_GBPACKERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Minnesota Vikings",
      id: "NFL024_MVIKINGS",
      icon: "Teams/nfl/NFL024_MVIKINGS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Atlants Falcons",
      id: "NFL025_AFALCONS",
      icon: "Teams/nfl/NFL025_AFALCONS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Carolina Panthers",
      id: "NFL026_CPANTHERS",
      icon: "Teams/nfl/NFL026_CPANTHERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "New Orleans Saints",
      id: "NFL027_NOSAINTS",
      icon: "Teams/nfl/NFL027_NOSAINTS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Tampa Bay Buccaneers",
      id: "NFL028_TBBUCCANEERS",
      icon: "Teams/nfl/NFL028_TBBUCCANEERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Arizona Cardinals",
      id: "NFL029_ACARDINALS",
      icon: "Teams/nfl/NFL029_ACARDINALS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Los Angeles Rams",
      id: "NFL030_LARAMS",
      icon: "Teams/nfl/NFL030_LARAMS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "San Francisco 49ers",
      id: "NFL031_SF49ERS",
      icon: "Teams/nfl/NFL031_SF49ERS.png",
      sport: "football",
      league: "nfl",
    },
    {
      name: "Seattle Seahawks",
      id: "NFL032_SSEAHAWKS",
      icon: "Teams/nfl/NFL032_SSEAHAWKS.png",
      sport: "football",
      league: "nfl",
    },
  ],
  epl_teams: [
    {
      name: "Arsenal",
      id: "EPL001_Arsenal",
      icon: "Teams/epl/EPL001_Arsenal.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Aston Villa",
      id: "EPL002_Aston_Villa",
      icon: "Teams/epl/EPL002_Aston_Villa.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Bournemouth",
      id: "EPL003_Bournemouth",
      icon: "Teams/epl/EPL003_Bournemouth.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Brentford",
      id: "EPL004_Brentford",
      icon: "Teams/epl/EPL004_Brentford.png",
      sport: "soccer",
      league: "epl",
    },

    {
      name: "Brighton & Hove Albion",
      id: "EPL005_Brighton_Hove_Albion",
      icon: "Teams/epl/EPL005_Brighton_Hove_Albion.png",
      sport: "soccer",
      league: "epl",
    },

    // {
    //   name: "Burnley F.C.",
    //   id: "EPL022_Burnley",
    //   icon: "Teams/epl/EPL022_Burnley.png",
    //   sport: "soccer",
    //   league: "epl",
    // },

    {
      name: "Chelsea",
      id: "EPL006_Chelsea",
      icon: "Teams/epl/EPL006_Chelsea.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Crystal Palace",
      id: "EPL007_Crystal_Palace",
      icon: "Teams/epl/EPL007_Crystal_Palace.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Everton",
      id: "EPL008_Everton",
      icon: "Teams/epl/EPL008_Everton.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Fulham",
      id: "EPL009_FULHAM",
      icon: "Teams/epl/EPL009_Fulham.png",
      sport: "soccer",
      league: "epl",
    },
    // {
    //   name: "Leeds United",
    //   id: "EPL010_Leeds_United",
    //   icon: "Teams/epl/EPL010_Leeds_United.png",
    //   sport: "soccer",
    //   league: "epl",
    // },
    {
      name: "Leicester City",
      id: "EPL011_Leicester_City",
      icon: "Teams/epl/EPL011_Leicester_City.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Liverpool",
      id: "EPL012_Liverpool",
      icon: "Teams/epl/EPL012_Liverpool.png",
      sport: "soccer",
      league: "epl",
    },
    // {
    //   name: "Luton",
    //   id: "EPL023_Luton",
    //   icon: "Teams/epl/EPL023_Luton.png",
    //   sport: "soccer",
    //   league: "epl",
    // },
    {
      name: "Manchester City",
      id: "EPL013_Manchester_City",
      icon: "Teams/epl/EPL013_Manchester_City.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Manchester United",
      id: "EPL014_Manchester_United",
      icon: "Teams/epl/EPL014_Manchester_United.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Newcastle United",
      id: "EPL015_Newcastle_United",
      icon: "Teams/epl/EPL015_Newcastle_United.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Nottingham Forest",
      id: "EPL016_Nottingham_Forest",
      icon: "Teams/epl/EPL016_Nottingham_Forest.png",
      sport: "soccer",
      league: "epl",
    },
    // {
    //   name: "Sheffield United",
    //   id: "EPL021_Sheffield_United",
    //   icon: "Teams/epl/EPL021_Sheffield_United.png",
    //   sport: "soccer",
    //   league: "epl",
    // },
    {
      name: "Southampton",
      id: "EPL017_Southampton",
      icon: "Teams/epl/EPL017_Southampton.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Spurs",
      id: "EPL018_Spurs",
      icon: "Teams/epl/EPL018_Spurs.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "West Ham United",
      id: "EPL019_West_Ham_United",
      icon: "Teams/epl/EPL019_West_Ham_United.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Wolverhampton Wanderers",
      id: "EPL020_Wolverhampton_Wanderers",
      icon: "Teams/epl/EPL020_Wolverhampton_Wanderers.png",
      sport: "soccer",
      league: "epl",
    },
    {
      name: "Ipswich_Town",
      id: "EPL024_Ipswich_Town",
      icon: "Teams/epl/EPL024_Ipswich_Town.png",
      sport: "soccer",
      league: "epl",
    },
  ],
  big10_teams: [
    {
      name: "Illinois",
      id: "BIG10001_Illinois",
      icon: "Teams/big10/BIG10001_Illinois.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Indiana",
      id: "BIG10002_Indiana",
      icon: "Teams/big10/BIG10002_Indiana.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Iowa",
      id: "BIG10003_Iowa",
      icon: "Teams/big10/BIG10003_Iowa.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Maryland",
      id: "BIG10004_Maryland",
      icon: "Teams/big10/BIG10004_Maryland.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Michigan State",
      id: "BIG10005_Michigan_State",
      icon: "Teams/big10/BIG10005_Michigan_State.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Michigan",
      id: "BIG10006_Michigan",
      icon: "Teams/big10/BIG10006_Michigan.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Minnesota",
      id: "BIG10007_Minnesota",
      icon: "Teams/big10/BIG10007_Minnesota.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Nebraska",
      id: "BIG10008_Nebraska",
      icon: "Teams/big10/BIG10008_Nebraska.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Northwestern",
      id: "BIG10009_Northwestern",
      icon: "Teams/big10/BIG10009_Northwestern.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Ohio State",
      id: "BIG10010_Ohio_State",
      icon: "Teams/big10/BIG10010_Ohio_State.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Penn State",
      id: "BIG10011_Penn_State",
      icon: "Teams/big10/BIG10011_Penn_State.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Purdue",
      id: "BIG10012_Purdue",
      icon: "Teams/big10/BIG10012_Purdue.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Rutgers",
      id: "BIG10013_Rutgers",
      icon: "Teams/big10/BIG10013_Rutgers.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Wisconsin",
      id: "BIG10014_Wisconsin",
      icon: "Teams/big10/BIG10014_Wisconsin.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Oregon Ducks",
      id: "BIG10015_oregon_ducks",
      icon: "Teams/big10/BIG10015_oregon_ducks.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "UCLA Bruins",
      id: "BIG10016_ucla_bruins",
      icon: "Teams/big10/BIG10016_ucla_bruins.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "USC Trojans",
      id: "BIG10017_usc_trojans",
      icon: "Teams/big10/BIG10017_usc_trojans.png",
      sport: "college",
      league: "big10",
    },
    {
      name: "Washington Huskies",
      id: "BIG10018_washington_huskies",
      icon: "Teams/big10/BIG10018_washington_huskies.png",
      sport: "college",
      league: "big10",
    },
  ],
};

export default TEAMS;
